import useSWR from 'swr'

export type Menu =
  | 'styleGuide'
  | 'editorRecommend'
  | 'share'
  | 'question'
  | 'membership'
  | 'ootd'
  | 'review'
  | 'vote'
  | 'styleTest'
  | 'why'
  | 'introduce'
  | 'notice'
  | 'faq'
  | 'whole'
  | 'best'
  | null

const KEY = 'MENU'

export default function useMenu() {
  const { data, mutate } = useSWR<Menu>(KEY)
  return {
    menu: data,
    setMenu: (value: Menu) => {
      return mutate(value)
    },
  }
}

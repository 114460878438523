import { useRouter } from 'next/router'
import tw, { css, styled } from 'twin.macro'
import StyledLink from '@/components/atoms/styled-link'

function Gnb({ children, ...rest }: React.PropsWithStrictChildren<unknown>) {
  return (
    <ul
      css={tw`flex flex-row text-14pxr text-grey400 justify-between h-38 all-child:not-last-of-type:mr-4`}
      {...rest}
    >
      {children}
    </ul>
  )
}

const Item = styled.li(({ isActive }: { isActive?: boolean }) => [
  tw`hover:(text-coral500 font-bold)`,
  isActive
    ? [
        css`
          border-bottom-style: solid;
          transition-duration: 0.5s;
        `,
        tw`font-bold text-coral500 border-b-2 border-coral500`,
      ]
    : css`
        border-bottom: 2px transparent solid;
      `,
])

function GnbItem({
  href,
  children,
}: React.PropsWithChildren<{ href: string }>) {
  const { pathname } = useRouter()

  return (
    <Item isActive={pathname.startsWith(href)}>
      <StyledLink href={href} css={tw`flex flex-center h-full`}>
        {children}
      </StyledLink>
    </Item>
  )
}

Gnb.Item = GnbItem

export default Gnb

import Link from 'next/link'
import { HTMLAttributeAnchorTarget } from 'react'
import tw from 'twin.macro'

export default function LinkButton({
  label,
  href,
  target,
  onClick,
  ...rest
}: React.PropsWithChildren<{
  label: string
  href: string
  target?: HTMLAttributeAnchorTarget | undefined
  onClick?: (e: unknown) => void
}>) {
  return (
    <Link href={href} onClick={onClick} passHref>
      <a target={target} css={[tw`flex flex-center rounded-4 px-8`]} {...rest}>
        {label}
      </a>
    </Link>
  )
}

import tw, { styled } from 'twin.macro'
import StyledLink from '@/components/atoms/styled-link'

function Lnb({
  isActive,
  children,
}: React.PropsWithStrictChildren<{ isActive: boolean }>) {
  return (
    <ul
      css={[
        tw`flex flex-row items-center h-61 overflow-x-auto all-child:not-last-of-type:mr-9 desktop:(h-70 flex-center all-child:not-last-of-type:mr-20)`,
        isActive && tw`hidden`,
      ]}
    >
      {children}
    </ul>
  )
}

const Item = styled.li(({ isActive }: { isActive?: boolean }) => [
  tw`flex h-29 border border-solid rounded-4 text-grey400 border-grey400 px-10 [flex: 0 0 auto] desktop:(h-47 px-20)`,
  isActive && tw`font-bold border-grey900 text-grey900 border`,
])

function LnbItem({
  href,
  isActive,
  children,
}: React.PropsWithChildren<{ href: string; isActive: boolean }>) {
  return (
    <Item isActive={isActive}>
      <StyledLink href={href} css={tw`flex flex-center`}>
        {children}
      </StyledLink>
    </Item>
  )
}

Lnb.Item = LnbItem

export default Lnb

import Link from 'next/link'
import { HTMLAttributeAnchorTarget } from 'react'

export default function StyledLink({
  href,
  children,
  target,
  onClick,
  ...rest
}: React.PropsWithChildren<{
  href: string
  target?: HTMLAttributeAnchorTarget | undefined
  onClick?: (e: unknown) => void
}>) {
  return (
    <Link href={href} onClick={onClick} passHref>
      <a target={target} {...rest}>
        {children}
      </a>
    </Link>
  )
}

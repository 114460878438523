import Image from 'next/future/image'
import tw from 'twin.macro'

export default function StyledImage({
  src,
  alt,
  fit = 'cover',
  ...rest
}: {
  src: string
  alt: string
  fit?: 'cover' | 'fill'
}) {
  return (
    <div css={[tw`relative w-full h-full overflow-hidden`]} {...rest}>
      <Image
        src={src}
        alt={alt}
        css={{
          ...(fit === 'cover' && tw`object-cover`),
          ...(fit === 'fill' && tw`object-fill`),
        }}
        fill
      />
    </div>
  )
}

import tw from 'twin.macro'
import { useRouter } from 'next/router'
import StyledLink from '@/components/atoms/styled-link'
import Gnb from '@/components/molecules/gnb'
import Lnb from '@/components/molecules/lnb'
import useDevicePlatform from '@/hooks/use-device-platform'
import useMenu from '@/store/use-menu'
import { useAmplitude } from '@/hooks/use-amplitude'
import StyledImage from '../atoms/styled-image'
import LinkButton from '../atoms/link-button'

export default function Header() {
  const router = useRouter()
  const { menu } = useMenu()
  const { href } = useDevicePlatform()
  const { track } = useAmplitude()

  return (
    <header>
      <nav css={tw`mx-20 desktop:(mx-auto w-[1200px])`}>
        <div
          css={tw`flex flex-row items-center h-57 desktop:(justify-between h-120)`}
        >
          <StyledLink href="/">
            <StyledImage
              src="/header/logo.svg"
              alt="logo"
              css={tw`w-72 h-38`}
            />
          </StyledLink>
          <Gnb css={tw`w-[800px] under-desktop:hidden`}>
            <Gnb.Item href="/style-guide">스타일 가이드</Gnb.Item>
            <Gnb.Item href="/magazine">매거진</Gnb.Item>
            <Gnb.Item href="/community">커뮤니티</Gnb.Item>
            <Gnb.Item href="/playground">놀이터</Gnb.Item>
            <Gnb.Item href="/service">서비스 소개</Gnb.Item>
          </Gnb>
          <div css={tw`mx-auto desktop:hidden`} />
          <div css={tw`flex flex-row items-center`}>
            <LinkButton
              label="회원가입"
              href="/sign-in"
              css={tw`text-14 h-27 bg-white`}
              onClick={(_) => track('web_click_sign_up_button')}
            />
            <LinkButton
              label="앱 다운받기"
              href={href}
              target="_blank"
              css={tw`text-14 text-white  bg-coral500 px-8 py-3`}
              onClick={(_) => track('web_click_download_button')}
            />
          </div>
        </div>
        <Gnb css={tw`desktop:hidden`}>
          <Gnb.Item href="/style-guide">스타일 가이드</Gnb.Item>
          <Gnb.Item href="/magazine">매거진</Gnb.Item>
          <Gnb.Item href="/community">커뮤니티</Gnb.Item>
          <Gnb.Item href="/playground">놀이터</Gnb.Item>
          <Gnb.Item href="/service">서비스 소개</Gnb.Item>
        </Gnb>
        <div css={tw``}>
          <Lnb isActive={!router.pathname.slice(1).startsWith('style-guide')}>
            <Lnb.Item href="/style-guide" isActive={menu === 'whole'}>
              가이드
            </Lnb.Item>
            <Lnb.Item href="/style-guide/best" isActive={menu === 'best'}>
              베스트 상품
            </Lnb.Item>
          </Lnb>
          <Lnb isActive={!router.pathname.slice(1).startsWith('community')}>
            <Lnb.Item href="/community" isActive={menu === 'whole'}>
              전체
            </Lnb.Item>
            <Lnb.Item
              href="/community?category=share"
              isActive={menu === 'share'}
            >
              스타일 나눔
            </Lnb.Item>
            <Lnb.Item
              href="/community?category=question"
              isActive={menu === 'question'}
            >
              스타일 질문
            </Lnb.Item>
            <Lnb.Item
              href="/community?category=membership"
              isActive={menu === 'membership'}
            >
              멤버십 전용
            </Lnb.Item>
            <Lnb.Item
              href="/community?category=ootd"
              isActive={menu === 'ootd'}
            >
              OOTD
            </Lnb.Item>
            <Lnb.Item
              href="/community?category=review"
              isActive={menu === 'review'}
            >
              쇼핑후기
            </Lnb.Item>
          </Lnb>
          <Lnb isActive={!router.pathname.slice(1).startsWith('playground')}>
            <Lnb.Item href="/playground" isActive={menu === 'whole'}>
              전체
            </Lnb.Item>
            <Lnb.Item href="/playground/vote" isActive={menu === 'vote'}>
              투표 결과
            </Lnb.Item>
            <Lnb.Item
              href="/playground/style-test"
              isActive={menu === 'styleTest'}
            >
              스타일 테스트
            </Lnb.Item>
          </Lnb>
          <Lnb isActive={!router.pathname.slice(1).startsWith('service')}>
            <Lnb.Item href="/service" isActive={menu === 'whole'}>
              전체
            </Lnb.Item>
            <Lnb.Item href="/service/why" isActive={menu === 'why'}>
              코콘 WHY
            </Lnb.Item>
            <Lnb.Item href="/service/introduce" isActive={menu === 'introduce'}>
              코콘 소개
            </Lnb.Item>
            <Lnb.Item href="/service/notice" isActive={menu === 'notice'}>
              공지사항
            </Lnb.Item>
            <Lnb.Item href="/service/faq" isActive={menu === 'faq'}>
              자주 묻는 질문
            </Lnb.Item>
          </Lnb>
        </div>
      </nav>
    </header>
  )
}

import tw from 'twin.macro'
import Fnb from '@/components/molecules/fnb'
import { useAmplitude } from '@/hooks/use-amplitude'
import StyledLink from '@/components/atoms/styled-link'
import StyledImage from '../atoms/styled-image'

export default function Footer() {
  const { track } = useAmplitude()

  return (
    <footer css={tw`bg-grey900 text-white text-12pxr px-20 pt-35 pb-85`}>
      <div
        css={tw`flex flex-col desktop:(flex-row-reverse w-[1200px] between-center mx-auto)`}
      >
        <div css={tw`flex flex-row mb-12 desktop:(w-[400px]) `}>
          <Fnb>
            <Fnb.Item>회사</Fnb.Item>
            <Fnb.Item href="mailto:cocon@blacktangerine.kr">
              소개서/투자미팅 요청
            </Fnb.Item>
            <Fnb.Item href="https://black-tangerine.oopy.io/428fe138-f79b-48f1-a396-4d3bd58591bd">
              만든 사람들
            </Fnb.Item>
            <Fnb.Item href="https://black-tangerine.oopy.io/">
              채용 안내
            </Fnb.Item>
            <Fnb.Item href="mailto:cocon@blacktangerine.kr">
              파트너십/기타 문의
            </Fnb.Item>
          </Fnb>
          <Fnb>
            <Fnb.Item>정책</Fnb.Item>
            <Fnb.Item href="https://cocon.style/pages/terms-of-service">
              회원약관
            </Fnb.Item>
            <Fnb.Item href="https://cocon.style/pages/privacy-policy">
              개인보호정책
            </Fnb.Item>
            <Fnb.Item href="#">마케팅이용동의</Fnb.Item>
          </Fnb>
        </div>
        <div css={tw`flex flex-col`}>
          <p
            css={tw`font-bold mb-8 desktop:mb-24`}
          >{`Copyright (주)블랙탠저린 ${new Date().getFullYear()}`}</p>
          <p css={tw`mb-2 desktop:mb-10`}>
            사업자 등록번호 : 527-86-02360 | 대표 : 김상이
          </p>
          <p css={tw`mb-17 desktop:mb-24`}>
            서울특별시 강남구 역삼로 180, MARU180, 3f
          </p>
          <div css={[tw`flex all-child:mr-12`]}>
            <StyledLink
              href="https://www.instagram.com/cocon.app/"
              target="_blank"
              onClick={(_) => track('web_click_insta_button')}
            >
              <StyledImage
                src="/footer/instagram.svg"
                alt="인스타그램"
                css={tw`w-40 h-40`}
              />
            </StyledLink>
            <StyledLink
              href="https://blog.naver.com/cocon_kr"
              target="_blank"
              onClick={(_) => track('web_click_blog_button')}
            >
              <StyledImage
                src="/footer/naver.svg"
                alt="네이버"
                css={tw`w-40 h-40`}
              />
            </StyledLink>
          </div>
        </div>
      </div>
    </footer>
  )
}

import tw, { styled } from 'twin.macro'
import StyledLink from '../atoms/styled-link'

function Fnb({ children }: React.PropsWithStrictChildren<unknown>) {
  return <ul css={tw`grow basis-0`}>{children}</ul>
}

const Item = styled.li(() => tw`mb-10 first:(font-bold mb-14) desktop:(mb-18)`)

function FnbItem({
  href,
  children,
  ...rest
}: React.PropsWithChildren<{ href?: string }>) {
  return (
    <Item>
      {href ? (
        <StyledLink href={href} {...rest}>
          {children}
        </StyledLink>
      ) : (
        <>{children}</>
      )}
    </Item>
  )
}

Fnb.Item = FnbItem

export default Fnb

import { useEffect, useMemo, useState } from 'react'

const APPLE_REGEX = /iphone|ipad/

const ANDROID_REGEX = /android/

export default function useDevicePlatform() {
  const [platform, setPlatform] = useState<
    'google' | 'apple' | 'desktop' | null
  >(null)
  useEffect(() => {
    if (window) {
      if (APPLE_REGEX.test(navigator.userAgent.toLocaleLowerCase())) {
        setPlatform('apple')
      } else if (ANDROID_REGEX.test(navigator.userAgent.toLocaleLowerCase())) {
        setPlatform('google')
      } else {
        setPlatform('desktop')
      }
    }
  }, [])

  const href: string = useMemo(() => {
    switch (platform) {
      case 'apple':
        return 'https://apps.apple.com/kr/app/cocon-%ED%8D%BC%EC%8A%A4%EB%84%90%EC%BB%AC%EB%9F%AC-%EC%9E%90%EA%B0%80-%EC%A7%84%EB%8B%A8-%ED%8C%A8%EC%85%98-%EC%BD%94%EB%94%94-ai-%EC%B6%94%EC%B2%9C/id1568307133'
      case 'google':
        return 'https://play.google.com/store/apps/details?id=style.cocon.app'
      default:
        return 'https://cocon.style/'
    }
  }, [platform])

  return { href }
}

import useSWR from 'swr'

const KEY = 'LOADING'

export default function useLoading() {
  const { data, mutate } = useSWR<boolean>(KEY)
  return {
    loading: data,
    setLoading: (value: boolean) => {
      return mutate(value)
    },
  }
}

import { AMPLITUDE_API_KEY } from '@/config'
import { init, track } from '@amplitude/analytics-browser'

function initialize() {
  if (typeof window !== 'undefined' && AMPLITUDE_API_KEY) {
    init(AMPLITUDE_API_KEY, undefined)
  }
}

initialize()

export function useAmplitude() {
  return { track }
}

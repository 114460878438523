import tw, { css } from 'twin.macro'
import { keyframes } from '@emotion/css'
import useLoading from '@/store/use-loading'

const animation = keyframes` 0% {
  opacity: 1;
  backface-visibility: hidden;
  transform: translateZ(0) scale(1.5, 1.5);
}
100% {
  opacity: 0;
  backface-visibility: hidden;
  transform: translateZ(0) scale(1, 1);
}`

function Loading() {
  const { loading } = useLoading()
  return (
    <div
      css={[
        tw`w-[100%] h-[100%] bg-black opacity-75 fixed flex flex-center z-50`,
        !loading && tw`hidden`,
      ]}
    >
      <div
        css={[
          css`
            width: 100px;
            height: 100px;
            display: inline-block;
            overflow: hidden;
          `,
        ]}
      >
        <div
          css={[
            css`
              width: 100%;
              height: 100%;
              position: relative;
              transform: translateZ(0) scale(1);
              backface-visibility: hidden;
              transform-origin: 0 0;

              div {
                box-sizing: content-box;
              }

              div > div {
                position: absolute;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background: #ff8a00;
                animation: ${animation} 1s linear infinite;
              }
              div:nth-child(1) > div {
                left: 74px;
                top: 44px;
                animation-delay: -0.875s;
              }
              > div:nth-child(1) {
                transform: rotate(0deg);
                transform-origin: 80px 50px;
              }
              div:nth-child(2) > div {
                left: 65px;
                top: 65px;
                animation-delay: -0.75s;
              }
              > div:nth-child(2) {
                transform: rotate(45deg);
                transform-origin: 71px 71px;
              }
              div:nth-child(3) > div {
                left: 44px;
                top: 74px;
                animation-delay: -0.625s;
              }
              > div:nth-child(3) {
                transform: rotate(90deg);
                transform-origin: 50px 80px;
              }
              div:nth-child(4) > div {
                left: 23px;
                top: 65px;
                animation-delay: -0.5s;
              }
              > div:nth-child(4) {
                transform: rotate(135deg);
                transform-origin: 29px 71px;
              }
              div:nth-child(5) > div {
                left: 14px;
                top: 44px;
                animation-delay: -0.375s;
              }
              > div:nth-child(5) {
                transform: rotate(180deg);
                transform-origin: 20px 50px;
              }
              div:nth-child(6) > div {
                left: 23px;
                top: 23px;
                animation-delay: -0.25s;
              }
              > div:nth-child(6) {
                transform: rotate(225deg);
                transform-origin: 29px 29px;
              }
              div:nth-child(7) > div {
                left: 44px;
                top: 14px;
                animation-delay: -0.125s;
              }
              > div:nth-child(7) {
                transform: rotate(270deg);
                transform-origin: 50px 20px;
              }
              div:nth-child(8) > div {
                left: 65px;
                top: 23px;
                animation-delay: 0s;
              }
              > div:nth-child(8) {
                transform: rotate(315deg);
                transform-origin: 71px 29px;
              }
            `,
          ]}
        >
          <div>
            <div />
          </div>
          <div>
            <div />
          </div>
          <div>
            <div />
          </div>
          <div>
            <div />
          </div>
          <div>
            <div />
          </div>
          <div>
            <div />
          </div>
          <div>
            <div />
          </div>
          <div>
            <div />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Loading

import '@/styles/globals.css'
import type { AppProps } from 'next/app'
import Header from '@/components/templates/header'
import Footer from '@/components/templates/footer'
import tw from 'twin.macro'
import { Router } from 'next/router'
import { useEffect } from 'react'
import Loading from '@/components/atoms/loading'
import useLoading from '@/store/use-loading'
import { NEXT_PUBLIC_URL } from '@/config'
import { DefaultSeo } from 'next-seo'

const DEFAULT_SEO = {
  title: '당신만의 AI 스타일리스트, 코콘 COCON',
  description: 'meta head description에 들어가는 값',
  canonical: NEXT_PUBLIC_URL,
  openGraph: {
    type: 'website',
    locale: 'ko_KR',
    url: NEXT_PUBLIC_URL,
    title: '당신만의 AI 스타일리스트, 코콘 COCON',
    site_name: '당신만의 AI 스타일리스트, 코콘 COCON',
    images: [
      {
        url: '/thumbnail.jpg',
        width: 400,
        height: 400,
        alt: '나는 웜톤일까? 쿨톤일까?',
      },
    ],
  },
}

function App({ Component, pageProps }: AppProps) {
  const { setLoading } = useLoading()

  useEffect(() => {
    const start = () => {
      setLoading(true)
    }
    const end = () => {
      setLoading(false)
    }

    Router.events.on('routeChangeStart', start)
    Router.events.on('routeChangeComplete', end)
    Router.events.on('routeChangeError', end)

    return () => {
      Router.events.off('routeChangeStart', start)
      Router.events.off('routeChangeComplete', end)
      Router.events.off('routeChangeError', end)
    }
  }, [setLoading])

  return (
    <>
      <Loading />
      <Header />
      <DefaultSeo {...DEFAULT_SEO} />
      <div css={tw`desktop:(w-[1200px] my-0 mx-auto)`}>
        <Component {...pageProps} />
      </div>
      <Footer />
    </>
  )
}

export default App
